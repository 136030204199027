
















import { Component, Vue, Prop } from 'vue-property-decorator'
import { ProjectResource } from '@/models/projects/ProjectResource'
import { FlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'
import { FlexibleDocumentMetaFieldResource } from '@/models/flexibleDocument/FlexibleDocumentMetaFieldResource'
import {convertToDate, formatDate} from '@/helpers/formatDate'

@Component
export default class FlexibleDocumentDetails extends Vue {

  @Prop()
  private project!: ProjectResource

  @Prop()
  private document!: FlexibleDocumentResource

  private getValue(field: FlexibleDocumentMetaFieldResource): string {
    if (!this.project.meta) return '-'
    const value = this.project.meta[field.key] ?? undefined

    // If field type is date and value is a string format it to a date and format it back to a formatted date string
    if (field.type === 'date') {
      return typeof value === 'string' ? formatDate(convertToDate(value)) : '-'
      // Dropdown value are either arrays or empty values
      // If array join them
    } else if (field.type === 'dropdown') {
      return Array.isArray(value) ? value.join(', ') : '-'
    } else {
      return typeof value === 'string' ? value : '-'
    }
  }


}
